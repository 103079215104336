import './App.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, {useEffect, useState} from 'react';
import {TextField, Button, Divider, ListItemButton, Paper, Typography, Container} from "@mui/material";
import Grid from '@mui/material/Grid';
import ResultsDisplayer from './ResultsDisplayer.js'
import Champions from './Champions.js';
import Confetti from "react-confetti";
import justin from './images/face_justin_victory.png'
import VictorySpeechModal from "./VictorySpeechModal";

function AppContent() {

    const [image, setImage] = useState(null);
    const [stopConfetti, setStopConfetti] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = justin;
        img.onload = () => setImage(img);
        img.onerror = () => console.error('Failed to load image at', img.src);
    }, []);

    const [open, setOpen] = React.useState(true);
    const [speech, setSpeech] = React.useState('I may not kno how to use Google docs, but I sure kno how to pickem');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (<Paper>
        {!stopConfetti && <Confetti gravity={0.05} frameRate={60}/>}
        {!stopConfetti && image && (
            <Confetti
                frameRate={60}
                gravity={0.05}
                drawShape={ctx => {
                    const scale = 0.2;
                    const x = -(image.width * scale) / 2;
                    const y = -(image.height * scale) / 2;
                    ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
                }}
            />

        )}
            <VictorySpeechModal
                open={open}
                handleClose={handleClose}
                speech={speech}
            />

        <Grid container rowSpacing={2}>
            <Grid item xs={12}><h1 align={"center"}>🎉CONGRATS JUSTIN🎉</h1></Grid>

            <Grid item xs={6}><Button variant="contained" onClick={() => setStopConfetti(!stopConfetti)}>Toggle Confetti</Button></Grid>
            <Grid item xs={6}><Button variant="contained"
                                      onClick={() => {
                                          handleOpen()
                                      }}

            >Victory Speech</Button></Grid>
            <Grid item xs={12}><ResultsDisplayer/> </Grid>
            <Grid item xs={12}>
                <Typography variant='h3'>Pickem Rules</Typography>
            </Grid>
            <Grid item xs={12}>
                Pick one NFL team each week to win their game. Once you have picked a team you can't choose them
                again. Whoever has the most correct picks at the end of the regular season is the champion.
                <List dense={true} sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}>
                    <Grid container xs={12} columnSpacing={2}>
                        <Grid item xs={6}>
                            <ListItem>
                                Must pick team before their game's listed start time online
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem>
                                Only wins count, TIES = LOSS
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem>
                                Tie breaker is the worst combined record of picked teams
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem>
                                We use the NFL's week grouping, so Thursday, Sunday, Monday are on the same week. <a
                                href='https://www.nfl.com/schedules/'>Here is the offical schedule</a>
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem>
                                You can only pick a team once.
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem>
                                If you double pick a team, you can change until the week is over. If you fail to
                                change to a valid pick it will be counted as a loss.
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Grid>

            <Grid item xs={12}>
                <a href="https://docs.google.com/spreadsheets/d/1WHWXEtrATFIcQN6aweIn4wfvkx5NtxI40inu41fybhI/edit?usp=sharing">
                    <Button variant="contained" size="large">Go to NFL Pick'em</Button>
                </a>
            </Grid>
        </Grid>
        <br/>
        <Divider/>
        <br/>
        <Champions/>
    </Paper>
)
    ;
}

export default AppContent;
