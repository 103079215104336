import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';

function ResultsDisplayer() {

  const [leaderboardData, setLeaderboardData] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('https://docs.google.com/spreadsheets/d/1WHWXEtrATFIcQN6aweIn4wfvkx5NtxI40inu41fybhI/pub?gid=0&single=true&output=csv');
      const data = await response.text();

      const lines = data.split('\n');
      const scores = lines[0].split(',').slice(1); // Skip the first element
      const people = lines[1].split(',').slice(1); // Skip the first element

      const leaderboard = people.map((person, index) => ({
        name: person,
        wins: parseInt(scores[index]) || 0,
      }));

      setLeaderboardData(leaderboard);

    } catch (error) {
      console.error('Error fetching CSV:', error);
    }
  };

  fetchData();
}, []);


  return (
    <div>
    <Typography variant='h3'>Leaderboard</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Rank</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Wins</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {leaderboardData.sort((a, b) => b.wins - a.wins).map((person, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{person.name}</TableCell>
            <TableCell>{person.wins}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </div>
  );
}

export default ResultsDisplayer;
